import React, { useState, ChangeEvent } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify'; // Use your preferred toast library
import { Channel, ChannelDto, PostApiChannelCreateChannelCreateReq, usePostApiChannelCreateChannelCreateMutation } from '../../api/channelApi'; // Updated import
import useApiErrorHandling, { ApiResponseError } from '../../hooks/useApiErrorHandling';
import { ChannelFormData, ChannelTypeE, LinkedOptions } from './interfaces/iTradeChannel';


interface ChannelCreateModalProps {
  show: boolean;
  onHide: () => void;
}

function ChannelCreateModal({ show, onHide }: ChannelCreateModalProps) {
  const [formData, setFormData] = useState<ChannelFormData>({
    Name: '',
    Description:'',
    EthereumWallet: null,
    DailyMinPosition: 0,
    WeeklyMinPosition: 0,
    MonthlyMinPosition: 0,
    ChannelType: ChannelTypeE.Public,    
    OwnerId: null,
    LinkedList:[]
  });

  const [createChannel, { isLoading, isError, isSuccess }] = usePostApiChannelCreateChannelCreateMutation();
  const { handleApiError } = useApiErrorHandling(); // Use the hook
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {

      

      const { data } = await createChannel({    channelDto: formData as unknown as ChannelDto  }).unwrap();
      toast.success('Channel created successfully');
      setFormData({
        Name: '',
        Description:'',
        EthereumWallet: null,
        DailyMinPosition: 0,
        WeeklyMinPosition: 0,
        MonthlyMinPosition: 0,
        ChannelType: ChannelTypeE.Public,    
        OwnerId: null,
        LinkedList:[]
      });
      setStep(0);
      onHide();
      // Reset form or navigate to the newly created channel, etc.
    } catch (error :any) {
      
      console.log("error", error.data);
      handleApiError(error.data as ApiResponseError);
      
      
    }
  };

  const handleLinkedItemChange = (e: ChangeEvent<any>, index: number) => {
    const { name, value } = e.target;
    const updatedLinkedItems = [...formData.LinkedList];
    updatedLinkedItems[index] = {
      ...updatedLinkedItems[index],
      [name]: value,
      name: value
    };
    setFormData({ ...formData, LinkedList: updatedLinkedItems });
  };
  const addLinkedItem = () => {
    setFormData({
      ...formData,
      LinkedList: [...formData.LinkedList, 
          { 
            lType: LinkedOptions.Telegram, 
            icon:'',
            name: '',
            url: '' }],
    });
  };

  const removeLinkedItem = (index: number) => {
    const updatedLinkedItems = [...formData.LinkedList];
    updatedLinkedItems.splice(index, 1);
    setFormData({ ...formData, LinkedList: updatedLinkedItems });
  };


  const [step, setStep] = useState(0); 
  return (
    <Modal show={show} onHide={onHide} centered={true} size='lg' scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title>Create Channel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className='form'>
          
          { step == 0 && 
          <><Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              required={true}
              placeholder="Enter name"
              name="Name"
              value={formData.Name}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"              
              required={true}
              placeholder="Enter Description"
              name="Description"
              value={formData.Description}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formEthereumWallet">
            <Form.Label>Ethereum Wallet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Ethereum Wallet"
              name="EthereumWallet"
              value={formData.EthereumWallet || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formChannelType">
            <Form.Label>Channel Type</Form.Label>
            <Form.Control as="select" name="ChannelType" required={true} value={formData.ChannelType.toString()} onChange={handleInputChange}>
              <option value={ChannelTypeE.Public}>Public</option>
              <option value={ChannelTypeE.Private}>Private</option>
            </Form.Control>
          </Form.Group>
          </>}          
          {step == 1 && (
            <>
              <Form.Label>Media Accounts</Form.Label>
              {formData.LinkedList.map((linkedItem, index) => (
                <InputGroup className="mb-2" key={index}>
                  <Form.Control
                    as="select"
                    name="lType"
                    value={linkedItem.lType}
                    onChange={(e) => handleLinkedItemChange(e, index)}
                  >
                    {Object.values(LinkedOptions).map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control style={{'marginTop':'17px'}}
                    type="text"
                    name="url"
                    placeholder={`Enter ${linkedItem.lType} URL`}
                    value={linkedItem.url}
                    onChange={(e) => handleLinkedItemChange(e, index)}
                  />
                  <Button variant="danger" size="sm" onClick={() => removeLinkedItem(index)}>
                    Remove
                  </Button>
                </InputGroup>
              ))}
              <Button variant="primary" size="sm" onClick={addLinkedItem}>
                Add Media Account
              </Button>
            </>)}
          {step == 2 && <>
          <Form.Group controlId="formDailyMinPosition">
            <Form.Label>Daily Min Position</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Daily Min Position"
              name="DailyMinPosition"
              value={formData.DailyMinPosition.toString()}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formWeeklyMinPosition">
            <Form.Label>Weekly Min Position</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Weekly Min Position"
              name="WeeklyMinPosition"
              value={formData.WeeklyMinPosition.toString()}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formMonthlyMinPosition">
            <Form.Label>Monthly Min Position</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Monthly Min Position"
              name="MonthlyMinPosition"
              value={formData.MonthlyMinPosition.toString()}
              onChange={handleInputChange}
            />
          </Form.Group>
          </>}
         
          
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" size="sm" onClick={onHide}>
          Close
        </Button>

        
        <Button variant="secondary" size='sm' disabled={step==0} onClick={() => setStep(step-1)}>Previous</Button>
        <Button variant="secondary" size='sm' disabled={step==2} onClick={() => setStep(step+1)}>Next</Button>
        {step ==2 && 
        <Button size="sm"
          variant="primary"
          onClick={handleSubmit}
          disabled={isLoading}
        >
         + Create
        </Button>}
      </Modal.Footer>
    </Modal>
  );
}

export default ChannelCreateModal;
