import Decors from "../partials/Decors";

const GuidePage = () => {


    return( <main className="site-content" id="wrapper">			
    <div className="site-content__inner">
    <div className="site-content__holder">
    <Decors>                            
            <h1 className="text-white">Guides</h1>
    </Decors>
        <div className="page-content">
        <h4 className="mb-5">
        Welcome to Coin Synthesis: The Ultimate Crypto Futures Trading Simulation
        </h4>
        <div className="row ">
            <div className="col-md-12">
                <p>
                    Are you ready to test your skills in the world of crypto futures trading? Coin Synthesis brings the thrill of the market directly to you, offering a dynamic and competitive platform where traders can show off their best—and even their worst—trades in real-time. Whether you're here to hone your strategies or simply compete with other top traders, Coin Synthesis offers an exciting and risk-free way to master the market.
                </p>
                    <h5>Real Market Data, Virtual Trading</h5>
                <p>                    
                    We connect directly to leading cryptocurrency exchanges to provide you with accurate futures and spot market data. Our simulated environment mimics real trading conditions, allowing you to experience the market without any financial risk. Perfect your strategies with no pressure of real-money losses!
                </p>
                    <h5>Equal Opportunity for All Traders</h5>                                            
                <p>
                    At Coin Synthesis, every trader starts on equal footing. Each trader begins with a virtual wallet of <b>$1000</b>, giving everyone the same opportunity to rise to the top. You can have <b>up to 5 active orders</b> and <b>5 open positions</b> at any time, and all traders enjoy <b>up to 10x leverage—making</b> each decision critical and exciting.
                </p>
                <h5>Realistic Margin and Fees</h5>
                <p>Just like real-world exchanges, we calculate Maintenance Margin rates and Funding Fees in a way that reflects live market conditions. This ensures your experience feels authentic while you plan and execute your trades. When a trader creates an order, their balance is adjusted based on the order size, keeping the $1000 total cap on orders and positions intact.</p>
                <h4>Trade Together in Channels</h4>
                <h5>Collaborate in Real-Time</h5>
                <p>
                    Our Channels are more than just groups—they are hubs where traders meet, discuss, and share their strategies live. When a trader opens a position, all channel members are instantly notified. Want to see how the pros do it? Join a public or private channel to watch their trades live as they happen.
                    Two Types of Channels: Public and Private
                    <br />
                    <b>Public Channels:</b> Open to everyone, allowing you to join and trade with others seamlessly. Watch trades unfold in real-time and learn from the best.
                    <br />
                    <b>Private Channels:</b> These are invite-only, providing a more exclusive space where traders can collaborate closely. Only the channel owner can invite traders or users to join.
                </p>
                <h5>Live Notifications</h5>
                <p>
                Stay informed and never miss a moment! Receive real-time notifications whenever a good or bad trade is closed by a channel member. Members in the channel can see all the details of the trade—including the exchange, trading pair, position size, stop-loss, and take-profit levels—while others outside the channel will only see masked details until the trade is closed.
                </p>
                <h4>Track Records and Future Rewards</h4>
                <h5>Every Trade Counts</h5>
                <p>
                All trade activities are logged and stored, enabling us to build detailed profiles of each trader’s performance. Whether you're making record-breaking trades or learning from your mistakes, your track record will always be available. Over time, Coin Synthesis will introduce achievements, leaderboards, and rewards, allowing the top traders to earn recognition and bragging rights.
                </p>

                <h3>
                Join Coin Synthesis today and take your place among the top crypto traders in the world—without risking a dime!
                </h3>
            </div>
        </div>
                </div>
            </div>
        </div>
    </main> )

};


export default GuidePage;